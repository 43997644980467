<template>
  <div class="groups">
    <h2 class="text-center" style="color: #0009">
      {{ "أنواع المعدات الرئيسية" | translate }}
    </h2>
    <hr />
    <div v-if="loading" class="center">
      <loading />
    </div>

    <div class="">
      <div
        v-for="cat in filteredCategories"
        :key="cat.name"
        class="row g-3 card"
        dir="rtl"
      >
        <div class="col-md-6">
          <h4 class="title-2">{{ cat.name }}</h4>
        </div>
        <!------------------------>
        <div class="col-md-6">
          <div class="">
            <span class="title-2">{{ "الآفَة" | translate }} </span>
            <span>({{ cat.element == 0 ? "لا يوجد" : cat.element | translate }})</span>
          </div>
        </div>
        <hr />
        <br />
        <!------------------------>
        <div class="col-md-12">
          <div class="form-group">
            <h5 class="title-2">{{ "الحالات" | translate }}</h5>
            <div class="row g-2">
              <div v-for="state in cat.states" :key="state.code" class="col-md-3">
                <span>({{ state.code }}) </span>
                <span>{{ state.desc }}</span>
              </div>
            </div>
          </div>
        </div>
        <!--------------------------->
        <!--------------------------->
        <div class="col-md-12">
          <div class="form-group">
            <h5 class="title-2">{{ "الأجراءات" | translate }}</h5>
            <div class="row g-2">
              <div
                v-for="procedure in cat.procedures"
                :key="procedure.code"
                class="col-md-3"
              >
                <span>({{ procedure.code }}) </span>
                <span>{{ procedure.desc }}</span>
              </div>
            </div>
          </div>
        </div>
        <!---------------------------->
        <!---------------------------->
        <div class="col-md-12">
          <hr />
          <div class="row g-2">
            <div class="col-md-6">
              <button @click="edit(cat._id)" class="btn btn-success">Edit</button>
            </div>
            <div class="col-md-6">
              <button @click="remove(cat._id)" class="btn btn-danger">delete</button>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  created() {
    this.loading = true;
    axios.get("/getCategories").then((res) => {
      this.loading = false;
      this.filteredCategories = res.data.categories;
    });
  },
  data() {
    return {
      loading: false,
      filteredCategories: [],
    };
  },
  methods: {
    remove(id) {
      const confirmed = confirm("مسح المعدة نهائيا..؟");
      if (!confirmed) return;
      axios.delete("/deleteCategory/" + id).then((res) => {
        if (res.data.err) return this.$store.dispatch("writeMessage", res.data.err);
        const index = this.filteredCategories.findIndex((d) => {
          return d._id == id;
        });
        this.filteredCategories.splice(index, 1);
        this.filteredCategories = [...this.filteredCategories];
      });
    },
    edit(id) {
      this.$router.push("/edit-category/" + id);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
}
.groups {
  padding: 20px;
  position: relative;
}
// @import "../../assets/styles/table.scss";
.search {
  margin: 15px;
  right: 0;
  // position: absolute;
  width: 40%;
  @media (max-width: 600px) {
    width: 100%;
  }
  i {
    position: absolute;
    right: 2%;
  }
}
.card {
  margin: 30px auto;
  border: 2px solid #363a45c3;
  padding: 5px;
}
button {
  margin: 2px;
}
</style>
